html {
  height: 100%;
}

html,
body {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#root {
  height: 100%;
}
