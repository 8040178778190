.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .row {
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
  }
}

.logo {
  max-width: 90%;
  margin: auto;
}

.faicon-row {
  font-size: 2.6rem;
  align-items: baseline;

  .faicon,
  span {
    margin-right: 1.2rem;
  }

  span {
    font-weight: 100;
    color: #4daa57;
    font-size: 3rem;
  }

  .faicon {
    color: #034c3c;

    &:hover {
      color: lighten(#034c3c, 10%);
    }
  }
}
